<template>
  <div class="notice-conten-con">
    <!-- <h2 class="h2-faq">자주 묻는 질문 TOP 20</h2> -->
    <div class="search-box faq-search-box">
      <input v-model="searchText" type="text" class="text" />
      <button class="btn __search" @click="search">검색</button>
    </div>
    <ul class="qna-list">
      <li v-for="(Board, idx) in Boards" :key="`Board_${idx}`">
        <div class="q" @click="toggleAnswer(Board.bordNo)">
          <!-- <p class="q1">{{ totalElements }}</p> -->
          <p class="q2">
            {{ `${no - idx + `&#46;&nbsp;` + Board.bordNm}` }}
          </p>
          <p class="q3">
            <span>{{ `${formatDate(Board.regDt)}` }}</span>
            <span>{{ `${Board.readCnt}` }}</span>
          </p>
        </div>
        <!-- <div v-html="Board.bordCont"></div> -->
        <div
          v-if="bordNum === Board.bordNo"
          class="noticeDetail-con"
          :class="{ on: bordNum === Board.bordNo }"
        >
          <div class="a noticeDetail">
            <div class="bordCont-head">
              <p class="noticeDetail-cnt">{{ `조회수 : ${Board.readCnt}` }}</p>
              <p class="noticeDetail-title" v-html="Board.bordNm"></p>
              <p class="noticeDetail-regDt">
                {{ `등록일자 : ${formatDate(Board.regDt)}` }}
              </p>
            </div>
            <div class="bordCont-Content">
              <div v-html="Board.bordCont"></div>
            </div>
            <div class="bordCont-bottom myp-btn-wrap">
              <button
                type="button"
                class="btn-save01"
                @click="toggleAnswer('close')"
              >
                닫기
              </button>
            </div>
          </div>
        </div>
      </li>
      <li v-if="Boards.length == 0">
        <div class="q">
          <p
            class="q2"
            style="margin: auto; text-align: center; color: #090909"
          >
            공지사항 내용이 존재하지 않습니다.
          </p>
        </div>
      </li>
    </ul>

    <Pagination
      :totalElements="totalElements"
      :currentPageIndex="currentPageIndex"
      :listRowCount="pageSize"
      :pageLinkCount="pageLinkCount"
      @goPage="onGoPage"
    ></Pagination>
  </div>
</template>

<script>
import { getBoardsList, getUpdateReadCnt } from 'Api/modules'
import Pagination from 'Common/Pagination'
import moment from 'moment'
// import { URL } from 'Api/api'
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      bordNum: null,
      searchText: '',
      Boards: [],

      // paging
      pageNumber: 0,
      totalElements: 0,
      pageSize: 20,
      pageLinkCount: 5,
      currentPageIndex: 1,
      propCd: 1,
      bordNo: '',
      no: '',
    }
  },
  computed: {},
  watch: {},
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD hh:mm:ss')
    },
    noteiceDetail(target) {
      if (target === 'close') {
        this.activeNum = null
      }
    },
    onGoPage(curPage) {
      this.currentPageIndex = curPage
      this.pageNumber = curPage - 1 //pageNumber 는 현재페이지에서 -1
      this.search()
    },
    async search() {
      const res = await getBoardsList({
        schKeyword: this.searchText,
        pageNumber: this.pageNumber,
        propCd: this.propCd,
        bordNo: this.bordNo,
        pageSize: this.pageSize,
      })
      this.Boards = res.content
      this.totalElements = res.totalElements
      this.currentPageIndex = res.pageable.pageNumber + 1
      this.pageNumber = res.pageable.pageNumber
      this.pageSize = res.pageable.pageSize
      this.no = this.totalElements - (this.currentPageIndex - 1) * this.pageSize
    },
    toggleAnswer(bordNo) {
      if (bordNo === 'close') {
        this.bordNum = null
        window.$('.container,.__contact,.sub-cont').css('position', 'relative')
        window.$('.mobileTopMenu').css('z-index', '1')
        window.$('.container').css('overflow-y', 'auto')
      } else {
        this.bordNum = bordNo
        this.updateReadCnt()
        window.$('.container,.__contact,.sub-cont').css('position', 'static')
        window.$('.mobileTopMenu').css('z-index', '0')
        window.$('.container').css('overflow-y', 'inherit')
      }
    },
    async updateReadCnt() {
      try {
        await getUpdateReadCnt({
          bordNo: this.bordNum,
        })
      } catch (e) {
        console.error(e)
      }
    },
  },
  created() {
    this.$nextTick(() => {
      this.search()
    })
  },
}
</script>

<style></style>
